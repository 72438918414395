<template>
  <template v-if="loading">
    <div
      v-bind="$attrs"
      class="border border-neutrals-200 rounded-2xl h-[80px] w-full max-w-lg pl-5 bg-white text-base flex items-center mt-5"
    >
      <NuxtImg
        width="40px"
        src="/images/loaders/rolling-blue-200px.gif"
        alt="Loading..."
      />
      <h5 class="mb-0 pl-3">Searching postcode</h5>
    </div>
  </template>

  <template v-else>
    <div
      v-bind="$attrs"
      class="relative z-10 w-full max-w-lg mt-5">
      <button
        @click="bookNow()"
        class="bg-primary text-white py-2 px-4 h-[50px] md:w-[140px] font-bold uppercase text-sm rounded-2xl absolute right-[15px] top-[15px] hidden sm:block"
      >
        Book Now
      </button>

      <input
        @keyup.enter="bookNow()"
        v-model="postcode"
        class="border border-neutrals-200 rounded-2xl h-[80px] w-full pl-5 bg-white text-base"
        type="text"
        placeholder="Enter your postcode"
      />
      <p class="mt-3 text-red">{{ error }}</p>

      <button
        @click="bookNow()"
        class="bg-primary text-white py-2 px-4 h-[50px] w-full max-w-md font-bold uppercase text-sm rounded-xl relative z-20 mt-3 block sm:hidden"
      >
        Book Now
      </button>
    </div>
  </template>
</template>
<script setup>
import { BookingStepType } from "~/types/BookingStepType";
import { storeToRefs } from "pinia";

const props = defineProps({
  type: String,
  equipmentTypeId: String,
  widgetKey: String,
});

const loading = ref(false);
const error = ref();
const postcode = ref();
const store = useBookingStore();
const { booking } = storeToRefs(store);
const { generateBooking, setPostcode, canBeOnPage, setStep, nextAttribute } = store;

if (process.client) {
  window.onpopstate = function () {
    loading.value = false;
  };
  // this.pageIsReady();
}

async function bookNow() {
  loading.value = true;

  try {
    await generateBooking(props.widgetKey);
    await setPostcode(postcode.value);

    if (!props.equipmentTypeId) {
      await skipCategoryPageForNow();
    }
  } catch (e) {
    loading.value = false;
    error.value = e.message;
  }
}

async function skipCategoryPageForNow() {
  const activeCategoryUuid = `6b935e88-082f-4430-8a44-191b3216681b`;

  await setStep(
    BookingStepType.Category,
    null,
    activeCategoryUuid
  );

  const attribute = await nextAttribute(
    activeCategoryUuid,
    null
  );

  return goToAttribute(attribute);
}

function goToAttribute(attribute) {
  return navigateTo({
    path: `/booking/attribute/${attribute.uuid}`,
  });
}
</script>
