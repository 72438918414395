import { useRouter } from 'nuxt/app'
import { ProjectLogo, WhiteLogo, BlueCircle } from '~/constants/AssetPaths'
import { useEventListener } from '@vueuse/core'

export function useNavigation() {
  const router = useRouter()
  const menuOpen = ref(false)
  const projectLogo = ref(ProjectLogo)
  const whiteLogo = ref(WhiteLogo)
  const blueCircle = ref(BlueCircle)
  const isDesktop = ref(false)

  const menuItems = ref([
    { name: "Booking", link: "/booking/location" },
    { name: "Skip Hire", link: "/c/waste/skip-hire" },
    { name: "Digger Hire", link: "/c/diggers-and-excavators" },
    { name: "Near Me", link: "/skip-hire/near-me" },
    { name: "About us", link: "/about-us" },
    { name: "Knowledge base", link: "/knowledge" },
    { name: "Contact", link: "/contact-us" },
  ])

  function handleMenuItemClick(link: string) {
    closeMenu()
    if (link) {
      router.push(link)
    }
  }

  function toggleBodyScroll(isDisabled: boolean) {
    if (isDisabled) {
      document.body.classList.add("overflow-hidden")
    } else {
      document.body.classList.remove("overflow-hidden")
    }
  }

  function toggleMenu() {
    menuOpen.value = !menuOpen.value
    toggleBodyScroll(menuOpen.value)
  }

  function closeMenu() {
    menuOpen.value = false
    toggleBodyScroll(false)
  }

  function handleResize() {
    isDesktop.value = window.innerWidth >= 1024
    if (isDesktop.value && menuOpen.value) closeMenu()
  }

  // Setup event listeners
  onMounted(() => {
    handleResize()
    useEventListener(window, "resize", handleResize)
  })

  const menuClass = computed(() => {
    if (menuOpen.value) return "bg-energy-green"
    return "bg-white"
  })

  return {
    menuOpen,
    projectLogo,
    whiteLogo,
    blueCircle,
    isDesktop,
    menuItems,
    menuClass,
    handleMenuItemClick,
    toggleMenu,
    closeMenu,
  }
} 