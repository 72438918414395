import { default as _91_46_46_46slug_93HqoN0TetvkMeta } from "/home/runner/work/spa/spa/pages/[...slug].vue?macro=true";
import { default as _91location_93xra22QPEhlMeta } from "/home/runner/work/spa/spa/pages/[category]/near-me/[location].vue?macro=true";
import { default as indexGykP1DKRHHMeta } from "/home/runner/work/spa/spa/pages/[category]/near-me/index.vue?macro=true";
import { default as indexNzWaQbWhI9Meta } from "/home/runner/work/spa/spa/pages/booking/add-on/index.vue?macro=true";
import { default as _91attributeUuid_93wtQEn5fXYBMeta } from "/home/runner/work/spa/spa/pages/booking/attribute/[attributeUuid].vue?macro=true";
import { default as basket1Dlq0ei8Z2Meta } from "/home/runner/work/spa/spa/pages/booking/basket.vue?macro=true";
import { default as _91categoryUuid_93th9QF0p0WqMeta } from "/home/runner/work/spa/spa/pages/booking/category/[categoryUuid].vue?macro=true";
import { default as checkoutFNA5ym22rMMeta } from "/home/runner/work/spa/spa/pages/booking/checkout.vue?macro=true";
import { default as confirmationOj8t0LPnTHMeta } from "/home/runner/work/spa/spa/pages/booking/confirmation.vue?macro=true";
import { default as end_45dateEV9Z6hKAo4Meta } from "/home/runner/work/spa/spa/pages/booking/end-date.vue?macro=true";
import { default as _91categoryUuid_93VgOENVGVLFMeta } from "/home/runner/work/spa/spa/pages/booking/equipment-type/[categoryUuid].vue?macro=true";
import { default as locationupgLLAvBIoMeta } from "/home/runner/work/spa/spa/pages/booking/location.vue?macro=true";
import { default as handleroWRKKWrqECMeta } from "/home/runner/work/spa/spa/pages/booking/payment/handler.vue?macro=true";
import { default as indexMj4EnxShhyMeta } from "/home/runner/work/spa/spa/pages/booking/payment/index.vue?macro=true";
import { default as _91orderReference_935El5FUbROoMeta } from "/home/runner/work/spa/spa/pages/booking/regenerate/[orderReference].vue?macro=true";
import { default as start_45dateBTZZOvfNZ6Meta } from "/home/runner/work/spa/spa/pages/booking/start-date.vue?macro=true";
import { default as contact_45usW0TLKQzGXJMeta } from "/home/runner/work/spa/spa/pages/contact-us.vue?macro=true";
import { default as cookie_45settingsbdHdejsAJrMeta } from "/home/runner/work/spa/spa/pages/cookie-settings.vue?macro=true";
import { default as _91equipmentTypeSlug_93GQ6u87kEYKMeta } from "/home/runner/work/spa/spa/pages/equipment/[equipmentTypeSlug].vue?macro=true";
import { default as _91attributeValueSlug_93XUtg3BUjC7Meta } from "/home/runner/work/spa/spa/pages/equipment/attributes/[categorySlug]/[attributeSlug]/[attributeValueSlug].vue?macro=true";
import { default as _91_46_46_46categorySlug_93GCFrifqes9Meta } from "/home/runner/work/spa/spa/pages/equipment/categories/[...categorySlug].vue?macro=true";
import { default as indexR30BMY20yBMeta } from "/home/runner/work/spa/spa/pages/index.vue?macro=true";
import { default as _91articleSlug_93rB2UBIKxLWMeta } from "/home/runner/work/spa/spa/pages/knowledge/[articleSlug].vue?macro=true";
import { default as _91_46_46_46slug_93i97PPLn0JSMeta } from "/home/runner/work/spa/spa/pages/knowledge/c/[...slug].vue?macro=true";
import { default as my_45bookingkAmo94DV0PMeta } from "/home/runner/work/spa/spa/pages/my-booking.vue?macro=true";
import { default as _91slug_93VQt1ORBGteMeta } from "/home/runner/work/spa/spa/pages/partners/[slug].vue?macro=true";
import { default as _91_46_46_46slug_93Jj7LeL5Qg8Meta } from "/home/runner/work/spa/spa/pages/pl/[...slug].vue?macro=true";
import { default as privacy_45policyQj8S70ql6JMeta } from "/home/runner/work/spa/spa/pages/privacy-policy.vue?macro=true";
import { default as _91email_93bnh48eWTEkMeta } from "/home/runner/work/spa/spa/pages/supplier/manage-booking/[orderReference]/[email].vue?macro=true";
import { default as terms_45and_45conditionsVCxU7Te62TMeta } from "/home/runner/work/spa/spa/pages/terms-and-conditions.vue?macro=true";
import { default as component_45stubJmSePiIOisMeta } from "/home/runner/work/spa/spa/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubJmSePiIOis } from "/home/runner/work/spa/spa/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/home/runner/work/spa/spa/pages/[...slug].vue")
  },
  {
    name: "category-near-me-location",
    path: "/:category()/near-me/:location()",
    component: () => import("/home/runner/work/spa/spa/pages/[category]/near-me/[location].vue")
  },
  {
    name: "category-near-me",
    path: "/:category()/near-me",
    component: () => import("/home/runner/work/spa/spa/pages/[category]/near-me/index.vue")
  },
  {
    name: "booking-add-on",
    path: "/booking/add-on",
    meta: indexNzWaQbWhI9Meta || {},
    component: () => import("/home/runner/work/spa/spa/pages/booking/add-on/index.vue")
  },
  {
    name: "booking-attribute-attributeUuid",
    path: "/booking/attribute/:attributeUuid()",
    meta: _91attributeUuid_93wtQEn5fXYBMeta || {},
    component: () => import("/home/runner/work/spa/spa/pages/booking/attribute/[attributeUuid].vue")
  },
  {
    name: "booking-basket",
    path: "/booking/basket",
    meta: basket1Dlq0ei8Z2Meta || {},
    component: () => import("/home/runner/work/spa/spa/pages/booking/basket.vue")
  },
  {
    name: "booking-category-categoryUuid",
    path: "/booking/category/:categoryUuid()",
    meta: _91categoryUuid_93th9QF0p0WqMeta || {},
    component: () => import("/home/runner/work/spa/spa/pages/booking/category/[categoryUuid].vue")
  },
  {
    name: "booking-checkout",
    path: "/booking/checkout",
    meta: checkoutFNA5ym22rMMeta || {},
    component: () => import("/home/runner/work/spa/spa/pages/booking/checkout.vue")
  },
  {
    name: "booking-confirmation",
    path: "/booking/confirmation",
    meta: confirmationOj8t0LPnTHMeta || {},
    component: () => import("/home/runner/work/spa/spa/pages/booking/confirmation.vue")
  },
  {
    name: "booking-end-date",
    path: "/booking/end-date",
    meta: end_45dateEV9Z6hKAo4Meta || {},
    component: () => import("/home/runner/work/spa/spa/pages/booking/end-date.vue")
  },
  {
    name: "booking-equipment-type-categoryUuid",
    path: "/booking/equipment-type/:categoryUuid()",
    meta: _91categoryUuid_93VgOENVGVLFMeta || {},
    component: () => import("/home/runner/work/spa/spa/pages/booking/equipment-type/[categoryUuid].vue")
  },
  {
    name: "booking-location",
    path: "/booking/location",
    meta: locationupgLLAvBIoMeta || {},
    component: () => import("/home/runner/work/spa/spa/pages/booking/location.vue")
  },
  {
    name: "booking-payment-handler",
    path: "/booking/payment/handler",
    meta: handleroWRKKWrqECMeta || {},
    component: () => import("/home/runner/work/spa/spa/pages/booking/payment/handler.vue")
  },
  {
    name: "booking-payment",
    path: "/booking/payment",
    meta: indexMj4EnxShhyMeta || {},
    component: () => import("/home/runner/work/spa/spa/pages/booking/payment/index.vue")
  },
  {
    name: "booking-regenerate-orderReference",
    path: "/booking/regenerate/:orderReference()",
    meta: _91orderReference_935El5FUbROoMeta || {},
    component: () => import("/home/runner/work/spa/spa/pages/booking/regenerate/[orderReference].vue")
  },
  {
    name: "booking-start-date",
    path: "/booking/start-date",
    meta: start_45dateBTZZOvfNZ6Meta || {},
    component: () => import("/home/runner/work/spa/spa/pages/booking/start-date.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/home/runner/work/spa/spa/pages/contact-us.vue")
  },
  {
    name: "cookie-settings",
    path: "/cookie-settings",
    component: () => import("/home/runner/work/spa/spa/pages/cookie-settings.vue")
  },
  {
    name: "equipment-equipmentTypeSlug",
    path: "/equipment/:equipmentTypeSlug()",
    component: () => import("/home/runner/work/spa/spa/pages/equipment/[equipmentTypeSlug].vue")
  },
  {
    name: "equipment-attributes-categorySlug-attributeSlug-attributeValueSlug",
    path: "/equipment/attributes/:categorySlug()/:attributeSlug()/:attributeValueSlug()",
    component: () => import("/home/runner/work/spa/spa/pages/equipment/attributes/[categorySlug]/[attributeSlug]/[attributeValueSlug].vue")
  },
  {
    name: "equipment-categories-categorySlug",
    path: "/equipment/categories/:categorySlug(.*)*",
    component: () => import("/home/runner/work/spa/spa/pages/equipment/categories/[...categorySlug].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/spa/spa/pages/index.vue")
  },
  {
    name: "knowledge-articleSlug",
    path: "/knowledge/:articleSlug()",
    component: () => import("/home/runner/work/spa/spa/pages/knowledge/[articleSlug].vue")
  },
  {
    name: "knowledge-c-slug",
    path: "/knowledge/c/:slug(.*)*",
    component: () => import("/home/runner/work/spa/spa/pages/knowledge/c/[...slug].vue")
  },
  {
    name: "my-booking",
    path: "/my-booking",
    component: () => import("/home/runner/work/spa/spa/pages/my-booking.vue")
  },
  {
    name: "partners-slug",
    path: "/partners/:slug()",
    meta: _91slug_93VQt1ORBGteMeta || {},
    component: () => import("/home/runner/work/spa/spa/pages/partners/[slug].vue")
  },
  {
    name: "pl-slug",
    path: "/pl/:slug(.*)*",
    component: () => import("/home/runner/work/spa/spa/pages/pl/[...slug].vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/home/runner/work/spa/spa/pages/privacy-policy.vue")
  },
  {
    name: "supplier-manage-booking-orderReference-email",
    path: "/supplier/manage-booking/:orderReference()/:email()",
    component: () => import("/home/runner/work/spa/spa/pages/supplier/manage-booking/[orderReference]/[email].vue")
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    component: () => import("/home/runner/work/spa/spa/pages/terms-and-conditions.vue")
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/home",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/equipment/2-yard-skip",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/equipment/4-yard-skip",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/equipment/6-yard-skip",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/equipment/8-yard-skip",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/equipment/10-yard-skip",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/equipment/12-yard-skip",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/equipment/14-yard-skip",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/equipment/16-yard-skip",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/knowledge/what-can-be-collected-by-grab-hire-",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/knowledge/do-you-need-insurance-to-hire-a-mini-digger-",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/equipment/categories/skips",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/equipment/categories/waste/skips",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/knowledge/what-can-I-put-into-a-skip",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/knowledge/what-can-i-put-into-a-skip",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/s/knowledge/what-can-you-put-into-a-skip",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/sl/skip-hire-",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/locations",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/locations/east-of-england",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/locations/north-east",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/locations/north-west",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/locations/south-east",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/locations/south-west",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/locations/west-midlands",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/locations/yorkshire",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/locations/wales",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/locations/west",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/locations/london",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/locations/east-midlands ",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/locations/scotland",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/locations/northern-ireland",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/sl/skip-hire-greater-manchester",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/sl/skip-hire-gloucestershire",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/sl/skip-hire-city-of-glasgow",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/sl/skip-hire-bedfordshire",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/sl/skip-hire-tyne-and-wear",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/equipment/categories/waste",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/p/8-yard-skip",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/knowledge/how-much-does-grab-hire-cost-",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/knowledge/8-uses-for-an-8-yard-skip-in-gloucester-versatile-waste-management-solutions",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/knowledge/advantages-of-a-mini-skip-manchester-a-smart-waste-management-solution",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/knowledge/best-way-to-use-a-4-yard-skip-gloucester-efficient-waste-management-tips",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/knowledge/8-yard-skips-in-glasgow:-a-perfect-solution-for-larger-waste-disposal",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/knowledge/cheap-skip-hire-manchester-your-ultimate-guide-to-affordable-waste-management-solutions",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/knowledge/everything-you-need-to-know-about-derby-council-skip-hire:-services-costs-and-alternatives",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/knowledge/mini-skips-in-glasgow:-a-convenient-solution-for-small-scale-waste-disposal",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/knowledge/the-circular-economy-in-action:-how-skip-hire-and-waste-management-can-drive-sustainability-in-construction",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/knowledge/the-ultimate-guide-to-efficient-waste-management-why-manchester-skip-hire-is-your-best-choice",
    component: component_45stubJmSePiIOis
  },
  {
    name: component_45stubJmSePiIOisMeta?.name,
    path: "/knowledge/why-skip-size-matters-when-it-comes-to-house-renovations-skip-size-gloucester",
    component: component_45stubJmSePiIOis
  }
]