export default defineNuxtRouteMiddleware((to) => {
  // Redirect /pl/skip-hire to /sl/skip-hire
  if (to.path.startsWith("/pl/skip-hire")) {
    return navigateTo(to.path.replace("/pl/", "/sl/"), { redirectCode: 301 });
  }

  // Redirect old location URLs to new skip hire URLs
  if (to.path.startsWith("/locations/category/skips/")) {
    const specialCases = {
      "bedfordshire": "bedford",
      "greater-manchester": "manchester",
      "gloucestershire": "gloucester",
      "tyne-and-wear": "newcastle",
      "city-of-glasgow": "glasgow"
    };

    const location = to.path.split("/").pop();
    const newLocation = specialCases[location] || location;
    
    return navigateTo(`/sl/skip-hire-${newLocation}`, { redirectCode: 301 });
  }

  if (to.path.startsWith("/equipment/attributes/skips")) {
    return navigateTo("/c/waste/skip-hire", { redirectCode: 301 });
  }
});
