<template>
  <Disclosure as="nav" v-slot="{ open }" :class="menuClass">
    <div class="max-w-full mx-auto relative z-50 h-[80px]">
      <div
        class="h-full flex justify-between items-center font-semibold text-sm xl:text-base constrain lg:text-white text-grey-5"
      >
        <!-- Logo -->
        <div class="flex items-center h-full">
          <NuxtLink to="/">
            <CommonSVGTemplate
              @click="closeMenu"
              class="lg:w-[185px] w-[129px] lg:h-[44px] h-[31px]"
              width="185"
              height="44"
              :alt="
                menuOpen ? 'JUSTHIRE white logo' : 'JUSTHIRE logo'
              "
              :path="
                !menuOpen && !isDesktop ? projectLogo : whiteLogo
              "
            />
          </NuxtLink>
        </div>

        <!-- Desktop Menu -->
        <div class="hidden lg:flex lg:ml-6 gap-6 xl:gap-12">
          <NuxtLink
            v-for="item in menuItems"
            :key="item.name"
            :to="item.link"
            class="inline-flex items-center border-b-2 border-b-transparent lg:hover:text-white lg:hover:border-white hover:text-primary-pressed hover:border-primary-pressed"
          >
            {{ item.name }}
          </NuxtLink>
        </div>

        <!-- Book a Skip Button -->
        <div class="hidden lg:flex items-center">
          <NuxtLink to="/booking/location">
            <Button intent="white-outline"> Book a skip </Button>
          </NuxtLink>
        </div>

        <!-- Hamburger Menu -->
        <div class="flex items-center lg:hidden">
          <DisclosureButton
            class="inline-flex items-center justify-center py-2 rounded-md text-base-black focus:outline-none"
            aria-label="Toggle menu"
          >
            <span class="sr-only">Open main menu</span>
            <component
              :is="menuOpen ? XMarkIcon : Bars3Icon"
              @click="toggleMenu"
              class="block h-10 w-10"
              :class="menuOpen || isDesktop ? 'text-white' : ''"
              aria-hidden="true"
            />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <!-- Mobile Menu -->
    <DisclosurePanel
      v-show="menuOpen"
      static
      class="lg:hidden fixed inset-0 mt-[80px] z-50 bg-white w-full h-full"
    >
      <CommonSVGTemplate
        alt="Gradient"
        width="100%"
        height="100%"
        :path="blueCircle"
        class="w-full h-full fixed z-10"
        loading="lazy"
      />

      <!-- Scrollable Container -->
      <div
        class="absolute z-20 w-full px-4 pt-2 pb-40 space-y-1 overflow-y-auto h-[calc(100vh-80px)]"
      >
        <span
          v-for="item in menuItems"
          :key="item.name"
          @click="handleMenuItemClick(item.link)"
          class="block border-b border-blue-lightest pr-4 py-6 text-base font-medium text-neutrals-800 leading-28"
        >
          {{ item.name }}
        </span>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import { useNavigation } from '~/composables/useNavigation';

const {
  menuOpen,
  projectLogo,
  whiteLogo,
  blueCircle,
  isDesktop,
  menuItems,
  handleMenuItemClick,
  toggleMenu,
  closeMenu,
} = useNavigation();

// Theme color specific logic
const THEME_COLORS = {
  menuOpen: "#28C700",
  default: "#FFFFFF",
};

function updateThemeColor(isOpen) {
  useHead({
    meta: [
      {
        name: "theme-color",
        content: isOpen ? THEME_COLORS.menuOpen : THEME_COLORS.default,
      },
    ],
  });
}

// Override toggleMenu to include theme color update
function handleToggleMenu() {
  toggleMenu();
  updateThemeColor(menuOpen.value);
}

// Override closeMenu to include theme color update
function handleCloseMenu() {
  closeMenu();
  updateThemeColor(false);
}

const menuClass = computed(() => {
  if (menuOpen.value) return "bg-energy-green";
  return "bg-white lg:bg-transparent lg:border-b-0";
});
</script>

<style scoped>
.invisible {
  opacity: 0;
}
</style>
