
import * as imagekitRuntime$XPwfrmQuwG from '/home/runner/work/spa/spa/node_modules/@nuxt/image-edge/dist/runtime/providers/imagekit'
import * as storyblokRuntime$62D1qySpFh from '/home/runner/work/spa/spa/node_modules/@nuxt/image-edge/dist/runtime/providers/storyblok'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "imagekit",
  "domains": [
    "imagekit.io",
    "ik.imagekit.io"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['imagekit']: { provider: imagekitRuntime$XPwfrmQuwG, defaults: {"baseURL":"https://ik.imagekit.io/justhire"} },
  ['storyblok']: { provider: storyblokRuntime$62D1qySpFh, defaults: {"baseURL":"https://a.storyblok.com"} }
}
        