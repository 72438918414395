export function useStoryblokLink() {
  const handleStoryblokLink = (blok) => {
    if (blok.linktype === 'story') {
      // Add leading slash if it doesn't exist for internal links
      return {
        ...blok,
        href: blok.href.startsWith('/') ? blok.href : `/${blok.href}`
      }
    }
    
    return blok
  }

  return {
    handleStoryblokLink
  }
} 