export const appBaseURL = "/"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon.png"},{"rel":"preload","href":"https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Koulen","as":"style"},{"rel":"preconnect","href":"https://fonts.googleapis.com","crossorigin":"anonymous"},{"rel":"preconnect","href":"https://fonts.gstatic.com","crossorigin":"anonymous"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Koulen"},{"rel":"preconnect","href":"https://www.googletagmanager.com","crossorigin":""},{"rel":"dns-prefetch","href":"https://www.googletagmanager.com"},{"rel":"preconnect","href":"https://sessions.bugsnag.com","crossorigin":""},{"rel":"dns-prefetch","href":"https://sessions.bugsnag.com"}],"style":[],"script":[{"src":"https://analytics.ahrefs.com/analytics.js","data-key":"JSBNX7MTHa4b66Z32B1AOA","async":true}],"noscript":[],"htmlAttrs":{"lang":"en-GB"}}

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"