<template>
  <component
    :is="partner?.link?.cached_url ? 'a' : 'div'"
    :href="partner?.link?.cached_url"
    v-editable="partner"
    class="h-[220px] lg:h-[380px] rounded-2xl overflow-clip relative block"
  >
    <!-- Partner Image -->
    <NuxtImg
      provider="storyblok"
      class="w-full h-full object-cover"
      :src="partner.image.filename"
      :alt="getImageAlt(partner.image)"
      loading="lazy"
      format="webp"
      width="450px"
    />

    <!-- Partner Logo Overlay -->
    <div class="flex items-center justify-center w-100 h-[48px] bg-white opacity-75 absolute bottom-[6px] left-[6px] right-[6px] rounded-[12px]">
      <NuxtImg
        provider="storyblok"
        width="143px"
        :src="partner.logo.filename"
        :alt="getImageAlt(partner.logo)"
        loading="lazy"
        format="webp"
      />
    </div>
  </component>
</template>
<script setup lang="ts">
interface Partner {
  image: {
    filename: string;
    alt?: string;
  };
  logo: {
    filename: string;
    alt?: string;
  };
  link?: {
    cached_url?: string;
  };
}

const props = defineProps({
  partner: {
    type: Object as () => Partner,
    required: true
  }
});

const { getImageAlt } = useImageAlt();
</script>
