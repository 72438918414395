export default function useImageAlt() {
  const generateAltFromFilename = (filename: string): string => {
    // Get the base filename without path and extension
    const baseName = filename.split('/').pop()?.split('.')[0] || '';
    
    // Replace dashes and underscores with spaces and capitalize first letter of each word
    return baseName
      .replace(/[-_]/g, ' ')
      .replace(/\b\w/g, char => char.toUpperCase());
  };

  const getImageAlt = (image: { filename: string; alt?: string }): string => {
    if (image.alt) return image.alt;
    return generateAltFromFilename(image.filename);
  };

  return {
    getImageAlt,
    generateAltFromFilename
  };
} 