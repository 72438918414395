<template>
<footer class="border border-t-[#ECECEC] bg-white relative z-30">
  <div class="constrain py-6 lg:py-20 text-grey-5">
    <div class="flex items-center justify-center lg:justify-between font-semibold">
      <NuxtLink to="/">
        <CommonSVGTemplate
          width="185"
          height="44"
          alt="JUSTHIRE logo"
          :path="ProjectLogo"
        />
      </NuxtLink>
      <div class="hidden lg:block flex space-x-8">
        <NuxtLink v-for="item in menuItems":to="item.link">
          {{ item.name }}
        </NuxtLink>
      </div>
    </div>
    <div class="flex justify-center lg:justify-between text-center lg:text-left">
      <div class="max-w-md mt-12">
        <p class="text-black font-bold mb-3">Stay Connected</p>
        <p>If you need to contact us for any reason please drop us an email, we'll aim to get back to you within hours</p>
        <Button as="nuxt-link" to="/contact-us" class="mt-6">Contact us</Button>
      </div>
    </div>
  </div>
  <div class="lg:hidden bg-base-white py-4">
    <div class="grid grid-cols-3 gap-3 text-center max-w-3xl m-auto">
      <NuxtLink v-for="item in menuItems" :to="item.link">
        {{ item.name }}
      </NuxtLink>
    </div>
  </div>
  <div class="bg-black w-full">
    <div class="constrain text-white py-6 flex flex-col lg:flex-row justify-between">
      <div class="flex space-x-4 m-auto lg:m-0">
        <p class="text-grey-3 hidden lg:block">© {{ new Date().getFullYear() }} JustHire. All rights reserved.</p>
        <NuxtLink class="underline" to="/privacy-policy">Privacy Policy</NuxtLink>
        <NuxtLink class="underline" to="/terms-and-conditions">Terms of Service</NuxtLink>
        <NuxtLink class="underline" to="/cookie-settings">Cookie Settings</NuxtLink>
      </div>
      <div class="flex space-x-12 m-auto lg:m-0 my-6  lg:my-0">
        <NuxtLink to="https://www.facebook.com/p/Just-Hire-100067230200291">
          <CommonSVGTemplate
            width="24"
            height="24"
            alt="Facebook Logo"
            :path="facebookLogo"
          />
        </NuxtLink>
        <NuxtLink to="https://www.instagram.com/justhireuk">
          <CommonSVGTemplate
            width="24"
            height="24"
            alt="Instagram Logo"
            :path="instagramLogo"
          />
        </NuxtLink>
        <NuxtLink to="https://x.com/JustHireUK">
          <CommonSVGTemplate
            width="24"
            height="24"
            alt="X Logo"
            :path="xLogo"
          />
        </NuxtLink>
      </div>
      <p class="text-grey-3 lg:hidden m-auto">© {{ new Date().getFullYear() }} JustHire. All rights reserved.</p>
    </div>
  </div>
</footer>
</template>
<script setup>
import {ProjectLogo} from "~/constants/AssetPaths";

// Menu items
const menuItems = ref([
  { name: 'Booking', link: '/booking/location' },
  { name: 'About us', link: '/about-us' },
  { name: 'Skip Hire', link: '/c/waste/skip-hire' },
  { name: "Digger Hire", link: "/c/diggers-and-excavators" },
  // { name: 'Our partners', link: '/knowledge' },
  // { name: 'Blog', link: '/contact-us' },
  { name: 'Knowledge base', link: '/knowledge' },
  { name: 'Near Me', link: '/skip-hire/near-me' },
  { name: 'Contact', link: '/contact-us' },
]);

const facebookLogo = `<path d="M22 12.3038C22 6.74719 17.5229 2.24268 12 2.24268C6.47715 2.24268 2 6.74719 2 12.3038C2 17.3255 5.65684 21.4879 10.4375 22.2427V15.2121H7.89844V12.3038H10.4375V10.0872C10.4375 7.56564 11.9305 6.1728 14.2146 6.1728C15.3088 6.1728 16.4531 6.36931 16.4531 6.36931V8.84529H15.1922C13.95 8.84529 13.5625 9.6209 13.5625 10.4166V12.3038H16.3359L15.8926 15.2121H13.5625V22.2427C18.3432 21.4879 22 17.3257 22 12.3038Z" fill="#B1A6B2"/>`
const instagramLogo = `<path fill-rule="evenodd" clip-rule="evenodd" d="M16 3.24268H8C5.23858 3.24268 3 5.48126 3 8.24268V16.2427C3 19.0041 5.23858 21.2427 8 21.2427H16C18.7614 21.2427 21 19.0041 21 16.2427V8.24268C21 5.48126 18.7614 3.24268 16 3.24268ZM19.25 16.2427C19.2445 18.0353 17.7926 19.4872 16 19.4927H8C6.20735 19.4872 4.75549 18.0353 4.75 16.2427V8.24268C4.75549 6.45003 6.20735 4.99817 8 4.99268H16C17.7926 4.99817 19.2445 6.45003 19.25 8.24268V16.2427ZM16.75 8.49268C17.3023 8.49268 17.75 8.04496 17.75 7.49268C17.75 6.9404 17.3023 6.49268 16.75 6.49268C16.1977 6.49268 15.75 6.9404 15.75 7.49268C15.75 8.04496 16.1977 8.49268 16.75 8.49268ZM12 7.74268C9.51472 7.74268 7.5 9.7574 7.5 12.2427C7.5 14.728 9.51472 16.7427 12 16.7427C14.4853 16.7427 16.5 14.728 16.5 12.2427C16.5027 11.0484 16.0294 9.90225 15.1849 9.05776C14.3404 8.21327 13.1943 7.74002 12 7.74268ZM9.25 12.2427C9.25 13.7615 10.4812 14.9927 12 14.9927C13.5188 14.9927 14.75 13.7615 14.75 12.2427C14.75 10.7239 13.5188 9.49268 12 9.49268C10.4812 9.49268 9.25 10.7239 9.25 12.2427Z" fill="#B1A6B2"/>`;
const xLogo = `<path d="M17.1761 4.24268H19.9362L13.9061 11.0201L21 20.2427H15.4456L11.0951 14.6493L6.11723 20.2427H3.35544L9.80517 12.9935L3 4.24268H8.69545L12.6279 9.3553L17.1761 4.24268ZM16.2073 18.6181H17.7368L7.86441 5.78196H6.2232L16.2073 18.6181Z" fill="#B1A6B2"/>`;
</script>
