<template>
  <StoryblokComponent v-if="story" :blok="story.content" />
</template>
<script setup>
const story = await useAsyncStoryblok("/home", {
  version: useRuntimeConfig().public.storyblokVersion,
});

// Get the first image from the story content or use a default
const ogImage = computed(() => {
  const content = story.value.content;
  // Look for hero image first
  if (content.body?.[0]?.image?.filename) {
    return content.body[0].image.filename;
  }
  // Fallback to default image
  return 'https://justhire.com/og-image.webp';
});

useSeoMeta({
  title: story.value.content.metatags.title,
  description: story.value.content.metatags.description,
  // Open Graph
  ogTitle: () => story.value.content.metatags.title,
  ogDescription: () => story.value.content.metatags.description,
  ogImage: () => ogImage.value,
  ogUrl: () => 'https://justhire.com',
  ogType: 'website',
  ogSiteName: 'JUSTHIRE',
  // Twitter
  twitterTitle: () => story.value.content.metatags.title,
  twitterDescription: () => story.value.content.metatags.description,
  twitterImage: () => ogImage.value,
  twitterCard: 'summary_large_image',
  twitterSite: '@justhire',
});

useHead({
  link: [
    { rel: 'canonical', href: 'https://justhire.com' }
  ],
})
</script>
